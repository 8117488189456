*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
nav{
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px 0 24px 2rem;
}
.large-name{
  color: #F3F3E7;
  font-size: 32px;
}
.small-name{
  display: none;
}
header{
  background-color: #2C394B;
  font-family: 'Poppins', sans-serif;
}
.error{
  font-family: 'Poppins', sans-serif;
}
header i{
    color: #c7c7c7;
    font-size: 2.5rem;
}
.cards-list{
  display: flex;
  gap: 20px;
  background-color: #082032;
  padding: 24px 12px;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-bottom: 40px;
}
/* .cd-bg{
  width: 340px;
} */
.icon-para{
    border-radius: 0px 0px 10px 10px;
    background-color:#2C394B;
    padding:30px;
    text-align: center;
    display: grid;
    place-items: center;
    /*position: relative;*/
}
.val-box {
  background-color: #334756;
  border-radius: 0 0 20px 20px;
  margin-top: -10px;
  padding: 10px;
  text-align: center;
}
.value{
  position:relative;
  color: #D8E9A8;
  font-size: 1.8rem;
  padding-top:12px;
  /*margin-top: 64px;*/
  font-family:'Montserrat', sans-serif;
  font-weight: 500;
}
.props-icon{
  color: #C7C7C7;
  text-align: center;
  font-size: 2.5rem;
}
.parameter{
  color: #CCCCCC;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
}
.value{
  color: #D8E9A8;
}
.smoke{
    display: flex;
    color: #FF2C2C;
    align-items: center;
    gap: 5px;
    justify-content: center;
}
.section-intro{
  background-color: #082032;
  font-family: 'Montserrat', sans-serif;
  color: #CCCCCC;
  font-weight: 300;
  padding-left: 2rem;
  padding-top: 20px;
}
.section-intro h2 {
  font-size: 3rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.section-intro p{
  font-size: 24px;
}
footer {
  background-color: #2c394b;
  color: #AAAAAA;
  padding: 24px 0 88px 0;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
.foo{
  margin-top: 12px;
}
.footer-items{
  padding-bottom: 2px;
}
@media screen and (max-width: 1273px) {
  .large-name{
    display: none;
  }
  .small-name{
    display: block;
    font-family: 'Montserrat', sans-serif;
    color: #F3F3E7;
  }
  nav{
    padding: 12px 0 12px 0;
    gap: 12px;
    justify-content: center;
  }
  .cards-list{
    display: flex;
    gap: 20px;
    padding-left: 2.4rem;
    justify-content: flex-start;
  }
  .cd-bg{
    width: 332px;
  }
  .smoke{
    font-size: 1.7rem;
  }
  .no-smoke{
    font-size: 1.7rem;
  }
  .section-intro{
    text-align: left;
    font-size: 1.18rem;
    padding-left: 0;
    padding-top: 12px;
  }
  .section-intro > div{
    padding-left: 2.4rem;
  }
  .section-intro h2{
    font-size: 1.8rem;
  }
  .section-intro p{
    font-size: 1.2rem;
  }
  footer{
    padding: 24px 0 28px 0;
  }
  .foo{
    margin-top: 8px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1086px) {
  .cd-bg{
    width: 231px;
  }
  .smoke-container{
    width: 482px;
  }
  .section-intro p{
    font-size: 20px;
  }
}

@media screen and (min-width: 30px) and (max-width: 400px) {
  .cards-list{
    padding-left: 7%;
    padding-right: 7%;
    justify-content: center;
  }
}